.plan_wrapper {
    height: 95%;
    .step {
      text-align: center;
      margin-top: 10px;
      p:first-child {
        font-weight: 600;
      }
      @media only screen and (min-width: 900px) {
        display: none;
      }
    }
}
.plan_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px;
    padding: 20px 0px;
    border-bottom: solid 2px #dededf;

    @media only screen and (max-width:  530px) {
      flex-direction: column;
    }

    .header_text {
      font-weight: 600;
      font-size: 1.3rem;
    }
    .offer {
        background-color: #ffcc00;
        color: black;
        font-weight: bold;
        font-size: 0.6rem;
        padding: 5px;
    }
}
.plan_body {
    height: 84%;

    .plan {
        height: 90%;

        .plan_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            min-height: 100%;
            margin-top: 40px;
        }
    }
}